import './VideoViewer.css';
import ShakaPlayer from 'shaka-player-react';
import 'shaka-player/dist/controls.css';
import {Tag} from "./Tag";
import {Navigation} from "./Menu";
import { useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';


function VideoViewer(props) {
    let {id} = useParams()
    let [video, setVideo] = useState(undefined)
    useEffect(() => {
        fetch(process.env.REACT_APP_BACKEND_URL + "/film/" + id)
            .then(response => response.json())
            .then(respJson => setVideo(respJson.data));
    }, [])
    return (<>
        <Navigation/><div className="content">
        { video !== undefined ?  
        <>
            <div className="content-header">
                { video.title }
            </div>
            <div className="content-tags">
                { video.tags.map((tag) => <Tag icon={tag.icon} text={tag.text}></Tag>) }
            </div>
            <div className="content-player">
                <ShakaPlayer autoPlay muted src={video.mpd}/>
            </div>
            </>
        : <>Loading..</>}
       </div>

    </>);
}

export default VideoViewer;
