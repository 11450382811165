// Create react component with icon and text properties

import React from 'react';
import './Tag.css';

export function Tag(props) {
    return (<div className="tag">
        <div className="tag-icon">
            {props.icon}
        </div>
        <div className="tag-text">
            {props.text}
        </div>
    </div>);
}