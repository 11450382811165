import './Literals.css';

export function Literals() {
    return (<div className="literals">
        <div className="upper-literal">
            citinez
        </div>
        <div className="lower-literal">
            cinema
        </div>
    </div>);
}