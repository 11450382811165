import './Menu.css'
import {Link} from "react-router-dom";

export function Navigation() {
    return <Link to={"/"}>
        <div className="menu">
            <div className="main">
                <i className="ri-arrow-left-line"></i>
            </div>
        </div>
    </Link>
}