import {useState, useEffect} from 'react';
import {Video} from "./Video";
import './Videos.css';

export function Videos() {
    const [videos, setVideos] = useState(undefined);
    useEffect(() => {
        fetch(process.env.REACT_APP_BACKEND_URL + "/films")
            .then(response => response.json())
            .then(respJson => setVideos(respJson.data.films));
    }, [])
    return (
        <div className="videos">
            {videos && videos.map(video => <Video video={video}/>)}
        </div>
    );
}