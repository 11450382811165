import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import {createBrowserRouter, RouterProvider,} from "react-router-dom";
import VideoViewer from './VideoViewer';
import {Literals} from "./Literals";
import {Videos} from "./Videos";

const router = createBrowserRouter([
    {
        path: "/",
        element: <Videos/>,
    },
    {
        path: "/video/:id",
        element: <VideoViewer/>,
    }
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
        <Literals/>
        <RouterProvider router={router}/>
    </React.StrictMode>
);
